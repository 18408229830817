import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Ipad from "../components/Ipad.js"
import { Link } from "gatsby"

const zero = () => {
  return (
    <Ipad data={{ bgColor: "var(--blu)" }}>
      <Container>
        <div className="header">
          <StaticImage
            className="logo"
            src="../images/logo-az.png"
            alt="logo"
            width={150}
          />
          <Link to="/">
            <StaticImage
              className="x"
              src="../images/x.png"
              alt="x"
              width={55}
            />
          </Link>
        </div>
        <div className="stuff">
          <h1>DIME QUÉ BUSCAS</h1>
          <div className="buttons">
            <div className="col">
              <Link to="/1a">
                <div className="button">
                  <StaticImage
                    className="img"
                    src="../images/twist.png"
                    alt="twist"
                    width={100}
                  />
                </div>
              </Link>
              <p>Nuevas formas de desconectar</p>
            </div>
            <div className="col">
              <Link to="/1b">
                <div className="button">
                  <StaticImage
                    className="img"
                    src="../images/palla.png"
                    alt="palla"
                    width={100}
                  />
                </div>
              </Link>
              <p>Reconectar con la naturaleza</p>
            </div>
            <div className="col">
              <Link to="/1c">
                <div className="button">
                  <StaticImage
                    className="img"
                    src="../images/diamond.png"
                    alt="diamond"
                    width={100}
                  />
                </div>
              </Link>
              <p>Explorar nuevas fronteras</p>
            </div>
          </div>
        </div>
      </Container>
    </Ipad>
  )
}

const Container = styled.section`
  width: 95%;
  margin: 0 auto;
  font-family: GEB;
  color: white;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100px;
    margin-top: 2vw;
    .logo,
    .x {
      align-self: flex-start;
    }
  }
  .stuff {
    margin-top: 7.5rem;
    h1 {
      font-size: 3.2rem;
      text-transform: uppercase;
      text-align: center;
    }
    .buttons {
      display: flex;
      width: 80%;
      justify-content: space-between;
      margin: 5rem auto 0 auto;
      .button {
        width: 220px;
        height: 220px;
        border-radius: 35px;
        border: 1px solid var(--azul);
        margin: 0 auto;
        text-align: center;
        position: relative;
        .img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      p {
        width: 220px;
        text-align: center;
        font-size: 1.4rem;
        line-height: 140%;
        margin-top: 0.9rem;
      }
    }
  }
`

export default zero
